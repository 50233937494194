<template>
  <div class="deductionSequence">
    <mt-header :title="$t('deductionSequence')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="tips">
        {{ $t('deductionSequenceTips') }}
        <span>{{ $t('longPressDrag') }}</span>
      </div>
      <draggable v-if="list.length" v-model="list" @end="onEnd">
        <mt-cell v-for="(item, index) in list" :key="index" :title="item.bankName">
          <img slot="icon" src="../../../assets/logo.png" height="22px" style="margin: 0px 10px 0px 8px;">
          <img src="../../../assets/sequence.png" width="19px">
        </mt-cell>
      </draggable>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import { mapState, mapMutations } from "vuex";
export default {
  name: 'DeductionSequence',
  components: {
    callPhone
  },
  data () {
    return {
      list: [],
    }
  },
  computed: {
    ...mapState(["bankCard"]),
  },
  watch: {
    bankCard: {
      handler(val) {
        this.list = val
      },
      immediate: true,
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations(['SET_BANK_CARD']),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await this.getBankCard()
      this.$indicator.close();
      this.$NProgress.done()
    },
    async getBankCard() {
      await new Promise(resolve => {
        setTimeout(() => {
          this.SET_BANK_CARD([
            {
              bankName: 'GCash',
              bankCardNumber: '****7778',
            }, {
              bankName: 'GGasp',
              bankCardNumber: '****6666',
            }
          ])
          resolve()
        }, 1000)
      })
    },
    onEnd() {
      this.$NProgress.start()
      console.log(this.list)
      setTimeout(() => {
        this.$NProgress.done()
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.deductionSequence {
  .content {
    padding: 22px;
    /deep/ .mint-cell-wrapper {
      padding-left: 0px;
    }
    .tips {
      margin: 15px 0px;
      font-size: 12px;
      color: #393939;
      line-height: 18px;
      span {
        font-size: 13px;
        color: #B0B0B0;
      }
    }
  }
}
</style>